import React from "react";
import * as yup from "yup";

import { Link } from "react-router-dom";
import authService from "services/auth-service";
import { BadInputError } from "common/errors";
import FormBase from "./shared/form-base";
import MuiCheckbox from "@material-ui/core/Checkbox";
import browserType from "common/browserType"

export default class Login extends FormBase {
  state = {
    data: { email: "", password: "", rememberMe: true },
    errors: {}
  };

  schema = yup.object().shape({
    email: yup
      .string()
      .email()
      .required()
      .label("Email"),
    password: yup
      .string()
      .required()
      .label("Password"),
    rememberMe: yup.boolean().label("Remember me")
  });

  handelResetPassword = async()=>{
    
  }

  submitData = async () => {
    try {
      console.log(browserType())

      const { email, password, rememberMe } = this.state.data;
      const { state } = this.props.location;
      const isChrome = browserType() === 'chrome';
      if(!isChrome) {
        alert('Unsupported browser. Please use Google Chrome.');
        return;
      }
      const logged = await authService.login(email, password, rememberMe);
      if (!logged) {
        alert('Wrong user name or password. Please check again.');
        return;
      }

      this.props.history.replace(state ? state.from.pathname : "/");
    } catch (error) {
      alert('Wrong user name or password. Please check again.');
      if (error instanceof BadInputError) {
        const errors = { ...this.state.errors };
        errors.email = error.data.message;
        this.setState({ errors });
      }
    }
  };

  render() {
    return (
      <div className="login-wrapper min-h-100-vh">
        <div className="container">
          <div className="row">
            <div className="col">
              <h1 className="text-center my-4 text-primary">ZIPLUNCH</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-8 m-auto">
              <div className="card">
                <div className="card-body">
                  <h2 className="text-center mb-5 font-weight-normal ">
                    Sign In
                  </h2>

                  <form onSubmit={this.handelSubmit} noValidate>
                    {this.renderInput(
                      "email",
                      "Your email",
                      "Enter your email"
                    )}
                    {this.renderInput(
                      "password",
                      "Your password",
                      "Enter your password",
                      "password"
                    )}

                    <div className="form-group">
                      <MuiCheckbox
                        id="rememberMe"
                        name="rememberMe"
                        onChange={this.handelControlChange}
                        checked={this.state.data.rememberMe}
                      />
                      <label
                        className="form-check-label px-1"
                        htmlFor="rememberMe"
                      >
                        Remember me
                      </label>
                      <label
                        onClick={this.handelResetPassword}
                        htmlFor="resetPassword"
                        style={{
                          color: "blue",
                          position: "absolute",
                          right: "73px",
                          paddingTop: "10px"
                        }}
                      ><Link to="/recover-password">
                        Recover password
                      </Link>
                      </label>
                    </div>

                    {this.renderSubmitButton(
                      "SIGN IN",
                      " btn-block  btn-lg mb-3 text-white"
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
