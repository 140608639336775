import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiDialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';

const Dialog = withStyles({
  paper: {
    color: 'inherit'
  }
})(MuiDialog);

const DialogContent = withStyles({
  root: {
    padding: '20px'
  }
})(MuiDialogContent);

export default ({
  show,
  onConfirm,
  onCancel,
  title,
  message,
  confirmTitle = 'Done',
}) => {
  return (
    <div>
      <Dialog fullWidth open={show} maxWidth="sm" onClose={onCancel}>
        <DialogTitle onClose={onCancel}>
          <div className="d-flex">
            <div className="flex-fill">{title}</div>
            <div>
              <i
                className="la la-times-circle-o cursor-pointer"
                onClick={onCancel}
              ></i>
            </div>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <div className="mb-5 mt-2 c-gray-700">{message}</div>
          <div className="d-flex px-1">
              <button
              style={{width:"200px",marginLeft:"auto",marginRight:"auto"}}
                className="btn btn-primary btn-block  btn-lg"
                onClick={onConfirm}
              >
                {confirmTitle}
              </button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};
