import React from 'react';

export default ({ name, label, isValid, errors, classes, ...rest }) => {
  return (
    <div className="form-group">
      {label && <label htmlFor={name}>{label}</label>}
      <textarea
        id={name}
        name={name}
        className={`form-control form-control-lg ${classes}`}
        {...rest}
      />
      {errors && <small className="form-text text-danger px-1">{errors}</small>}
    </div>
  );
};
