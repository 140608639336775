import { BadInputError } from 'common/errors';
import React, { Component } from 'react';
import toastService from 'services/toast-service';
import Checkbox from './controls/checkbox';
import FileUploader from './controls/file-uploader';
import Input from './controls/input';
import Select from './controls/select';
import Switch from './controls/switch';
import TagsInput from './controls/tags-input';
import Textarea from './controls/textarea';

export default class FormBase extends Component {
  state = { data: {}, relatedData: null, errors: {} };

  service = null;
  addedMessage = 'Item added successfully';
  updatedMessage = 'Item updated successfully';

  componentDidMount() {
    const { editItem } = this.props;
    if (!editItem) return;
    const data = {};
    Object.keys(this.state.data).forEach(key => {
      data[key] = editItem[key];
    });
    this.setState({ data });
  }

  handelSubmit = e => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    this.submitData();
  };
  submitData = async () => {
    const data = { ...this.state.data };
    this.saveItem(data);
  };

  getIsInEditMode = () => {
    return false;
  };

  saveItem = async item => {
    try {
      if (!this.service) return;

      const { editItem } = this.props;

      let newItem = null;
      if (editItem || this.getIsInEditMode() == true) {
        item.id = editItem.id;
        try {
          newItem = await this.service.update(item);
          toastService.success(this.updatedMessage);
        } catch (err) {
          console.log(err);
        }
      } else {
        try {
          newItem = await this.service.add(item);
          toastService.success(this.addedMessage);
        } catch (err) {
          console.log(err);
        }
      }
      if (this.props.onFormSaved) {
        this.props.onFormSaved(newItem);
      }
    } catch (error) {
      if (error instanceof BadInputError) {
        this.setState({ errors: { ...this.state.errors, ...error.errors } });
        return;
      }
      throw error;

    }
  };

  validate = () => {
    try {
      this.schema.validateSync(this.state.data, {
        context: { ...this.state.data, componentProps: this.props },
        abortEarly: false
      });
      return null;
    } catch (error) {
      const errors = {};
      if (error.inner)
        for (const item of error.inner) errors[item.path] = item.message;
      return errors;
    }
  };

  handelControlChange = ({ currentTarget: input }) => {
    let value = input.value;

    if (input.type === 'checkbox') value = input.checked;
    if (input.type === 'number' && value) value = parseFloat(input.value);
    if (input.type === 'select-one' && value) {
      value = /^\d+$/.test(value) ? parseInt(value) : value;
    }
    if (input.type === 'select-multiple') {
      value = [];
      for (let i = 0; i < input.options.length; i++) {
        const val = parseInt(input.options[i].value);
        if (input.options[i].selected && val) {
          value.push(val);
        }
      }
    }

    const name = input.name;
    this.dataChanged(name, value);
  };

  handelFileChange = (name, url) => {
    this.dataChanged(name, url);
  };

  dataChanged = (name, value) => {
    const data = { ...this.state.data };
    const errors = { ...this.state.errors };
    const { relatedData } = this.state;

    data[name] = value;

    this.checkDataPropertyErrors(data, errors, name);
    if (relatedData && relatedData[name]) {
      relatedData[name].forEach(relatedName => {
        this.checkDataPropertyErrors(data, errors, relatedName);
      });
    }

    this.setState({ data, errors });
  };

  checkDataPropertyErrors = (data, errors, name) => {
    const errorMessage = this.validateInputProperty(data, name);
    if (errorMessage) errors[name] = errorMessage;
    else delete errors[name];
  };

  validateInputProperty = (data, name) => {
    try {
      this.schema.validateSyncAt(
        name,
        { [name]: data[name] },
        { context: { ...data, componentProps: this.props } }
      );
      return null;
    } catch (error) {
      return error.errors[0];
    }
  };

  renderInput = (name, label = '', placeholder = '', type = 'text', disabled = false) => {
    return (
      <Input
        name={name}
        label={label}
        value={this.state.data[name] || ''}
        onChange={this.handelControlChange}
        isValid={this.state.errors[name] ? false : true}
        errors={this.state.errors[name]}
        type={type}
        maxLength={100}
        placeholder={placeholder}
        disabled={disabled}
      />
    );
  };

  renderTextarea = (name, label = '', placeholder = '', classes = '') => {
    return (
      <Textarea
        name={name}
        label={label}
        value={this.state.data[name] || ''}
        onChange={this.handelControlChange}
        isValid={this.state.errors[name] ? false : true}
        errors={this.state.errors[name]}
        maxLength={300}
        placeholder={placeholder}
        classes={classes}
      />
    );
  };

  renderCheckbox = (name, label = '') => {
    return (
      <Checkbox
        name={name}
        label={label}
        value={this.state.data[name]}
        onChange={this.handelControlChange}
        isValid={this.state.errors[name] ? false : true}
        errors={this.state.errors[name]}
      />
    );
  };

  renderSwitch = (name, label = '') => {
    return (
      <Switch
        name={name}
        label={label}
        value={this.state.data[name]}
        onChange={this.handelControlChange}
        isValid={this.state.errors[name] ? false : true}
        errors={this.state.errors[name]}
      />
    );
  };

  renderSelect(
    name,
    label = '',
    options = [],
    defaultOption = '---Select item---',
    multiple = false,
    value = '',
    disabled = false
  ) {
    return (
      <Select
        name={name}
        label={label}
        value={this.state.data[name] || value || (multiple ? [] : '')}
        options={options}
        defaultOption={defaultOption}
        isValid={this.state.errors[name] ? false : true}
        error={this.state.errors[name]}
        onChange={this.handelControlChange}
        multiple={multiple}
        disabled={disabled}
      />
    );
  }

  renderFileUploader = (name, label = '', uploadBtnLabel = 'Upload Photo', isForceCrop) => {
    return (
      <FileUploader
        name={name}
        label={label}
        uploadBtnLabel={uploadBtnLabel}
        value={this.state.data[name] || ''}
        onChange={url => {
          this.handelFileChange(name, url);
        }}
        isValid={this.state.errors[name] ? false : true}
        errors={this.state.errors[name]}
        isForceCrop={isForceCrop}
      />
    );
  };

  renderTagsInput = (
    name,
    label = '',
    suggestions = [],
    placeholder = '',
    allowNew = false
  ) => {
    return (
      <TagsInput
        name={name}
        label={label}
        value={this.state.data[name] || []}
        onChange={tags => this.dataChanged(name, tags)}
        isValid={this.state.errors[name] ? false : true}
        errors={this.state.errors[name]}
        placeholder={placeholder}
        suggestions={suggestions}
      />
    );
  };

  renderSubmitButton = (label = 'Save', classes = '') => {
    return (
      <button
        type="submit"
        disabled={this.validate() ? true : false}
        className={`btn btn-primary ${classes}`}
      >
        {label}
      </button>
    );
  };

  renderCancelButton = (onClick, label = 'Cancel', classes = '') => {
    return (
      <button
        type="button"
        className={`btn btn-light ${classes}`}
        onClick={onClick}
      >
        {label}
      </button>
    );
  };
}
