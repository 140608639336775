import axios from "axios";

import { trackPromise } from "react-promise-tracker";

import logger from "services/logger-service";
import toastService from "services/toast-service";
import tokenUtils from "common/token-utils";
import {
  AppError,
  UnauthorizedError,
  BadInputError,
  NotFoundError
} from "common/errors";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;

axios.interceptors.request.use(
  config => {
    const url = config.url.split("/");
    if (url[url.length - 1] === "login") return config;

    const user = tokenUtils.getLoggedUser();
    if (user) config.headers.Authorization = `Bearer ${user.accessToken}`;

    return config;
  },
  error => Promise.reject(error)
);

axios.interceptors.response.use(
  response => {
    if (response.headers.authorization)
      tokenUtils.refreshLoggedUserToken(response.headers.authorization);
    return response;
  },
  error => {
    const { response } = error;
    if (response && response.status >= 400 && response.status < 500)
      return handelExpectedError(error);
    return handelUnexpectedError(error);
  }
);

function handelUnexpectedError(error) {
  toastService.error("An unexpected error occurred");
  logger.log(error);
  return Promise.reject(error);
}

function handelExpectedError(error) {
  const { response } = error;

  if (response.status === 400 || response.status === 422) {
    toastService.error(response.data.result);
    return Promise.reject(error);
  }

  if (response.status === 401 || response.status === 403) {
    return Promise.reject(new UnauthorizedError(response.data, error));
  }

  if (response.status === 404) {
    toastService.error(response.data.result);
    return Promise.reject(new NotFoundError(response.data, error));
  } else {
    return Promise.reject(new AppError(response.data, error));
  }
}

export default {
  get: (...args) => trackPromise(axios.get(...args)),
  post: (...args) => trackPromise(axios.post(...args)),
  put: (...args) => trackPromise(axios.put(...args)),
  patch: (...args) => trackPromise(axios.patch(...args)),
  delete: (...args) => trackPromise(axios.delete(...args))
};
