import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import 'assets/css/line-awesome.css';
import 'react-toastify/dist/ReactToastify.css';
import 'assets/scss/main.scss';

import Login from 'components/login';
import RecoverPassword from 'components/recover-password';
import ResetPassword from 'components/reset-password';
import NotFound from 'components/not-found';
import App from 'app';

import * as serviceWorker from 'serviceWorker';

ReactDOM.render(
  <React.Fragment>
    <ToastContainer />
    <BrowserRouter>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/recover-password" component={RecoverPassword} />
        <Route path="/resetpassword" component={ResetPassword} />
        <Route path="/not-found" exact component={NotFound} />
        <Route path="/" render={props => <App {...props} />} />
      </Switch>
    </BrowserRouter>
  </React.Fragment>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
