import React from 'react';

export default ({ name, label, isValid, errors, ...rest }) => {
  return (
    <div className="form-group">
      {label && <label htmlFor={name}>{label}</label>}
      <input
        id={name}
        name={name}
        className="form-control form-control-lg"
        {...rest}
      />
      {errors && <small className="form-text text-danger px-1">{errors}</small>}
    </div>
  );
};
