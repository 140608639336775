import React from 'react';
import { usePromiseTracker } from 'react-promise-tracker';

export default props => {
  const { promiseInProgress } = usePromiseTracker();

  return (
    promiseInProgress && (
      <div className="spinner">
        <div className="d-flex h-100">
          <div className="m-auto">
            <i className="la la-4x la-spinner"></i>
          </div>
        </div>
      </div>
    )
  );
};
