import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import { UnauthorizedError, NotFoundError } from 'common/errors';
import authService from 'services/auth-service';
import Sidebar from 'components/shared/sidebar';
import Header from 'components/shared/header';
import Spinner from 'components/shared/spinner';
import Routes from 'routes';
import { decodeToken } from 'components/shared/controls/token-decode.js';

export default class App extends Component {
  state = { sidebarOpened: true };

  componentDidMount() {
    window.onunhandledrejection = err => {
      if (err.reason instanceof UnauthorizedError) {
        authService.logout();
        window.location.replace('/login');
      }

      if (err.reason instanceof NotFoundError) {
        window.location.replace('/not-found');
      }
    };
    const token = JSON.parse(localStorage.getItem('user'))
    token && this.setState({ userRealm: decodeToken(token.accessToken).realm });
  }

  componentWillMount() {
    let url = window.location.href.toLowerCase();
    if (url.indexOf('http') === 0 && url.indexOf('localhost') < 0) { //if using browser not mobile application
      if (window.location.protocol !== "https:") { //if url is not secure then switch to secure
        window.location.href = window.location.href.replace('http', 'https');
      }
    }
  }

  handelToggleSidebar = () => {
    this.setState({ sidebarOpened: !this.state.sidebarOpened });
  };

  render() {
    const { sidebarOpened } = this.state;
    if (!authService.getCurrentUser())
      return (
        <Redirect
          to={{ pathname: '/login', state: { from: this.props.location } }}
        />
      );

    return (
      <div className={`page ${sidebarOpened ? 'sidebar-opened' : ''}`}>
        <Sidebar
          userRealm={this.state.userRealm}
          {...this.props}
          onToggleSidebar={this.handelToggleSidebar}
        ></Sidebar>
        <div className="page-content">
          <Header {...this.props} onToggleSidebar={this.handelToggleSidebar} />
          <div className="page-body">
            <Spinner></Spinner>
            <Routes userRealm={this.state.userRealm}></Routes>
          </div>
        </div>
      </div>
    );
  }
}
