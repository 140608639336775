import React, { useState } from 'react';
import DropdownMenu from 'components/shared/controls/dropdown-menu';

import authService from 'services/auth-service';

export default ({ location, history, onToggleSidebar }) => {
  const [openedMenu, setOpenedMenu] = useState({
    chat: null,
    notification: null,
    profile: null
  });

  const getPageName = () => {
    switch (location.pathname) {
      case '/dashboard':
        return 'Dashboard';

      case '/by-location':
        return 'By Delivery Hub';

      case '/users':
        return 'Ziplunch Users';

      case '/customers':
        return 'Customers';

      case '/companies':
        return 'Locations';

      case '/corporate':
        return 'Corporate';

      case '/restaurants':
        return 'Restaurants';

      case '/hubs':
        return 'Delivery Hubs';

      case '/orders':
        return 'Orders';

      case '/tags':
        return 'Tags';

      case '/neighborhoods':
        return 'Neighborhoods';

      default:
        return null;
    }
  };

  const handleOpen = (e, menuKey) => {
    const menu = { ...openedMenu };
    menu[menuKey] = e.currentTarget;
    return setOpenedMenu(menu);
  };

  const handleClose = menuKey => {
    const menu = { ...openedMenu };
    menu[menuKey] = null;
    return setOpenedMenu(menu);
  };

  const user = authService.getCurrentUser();
  return (
    <nav className="navbar navbar-expand">
      <span
        className="navbar-toggler-icon cursor-pointer"
        onClick={onToggleSidebar}
      >
        <i className="la la-bars"></i>
      </span>

      <span className="navbar-brand">{getPageName()}</span>
      <div className="collapse navbar-collapse">
        <ul className="navbar-nav ml-auto">
          {/* <li className="nav-item ">
            <span
              className="nav-link cursor-pointer"
              onClick={e => handleOpen(e, 'chat')}
            >
              <i className="la la-comments"></i>
            </span>
            <div>
              <DropdownMenu
                items={['Chat 1', 'Chat 2', 'Chat 3']}
                openElRef={openedMenu.chat}
                onClose={() => handleClose('chat')}
              />
            </div>
          </li> */}

          {/* <li className="nav-item ">
            <span
              className="nav-link cursor-pointer"
              onClick={e => handleOpen(e, 'notification')}
            >
              <i className="la la-bell"></i>
            </span>
            <div>
              <DropdownMenu
                items={['Notification 1', 'Notification 2', 'Notification 3']}
                openElRef={openedMenu.notification}
                onClose={() => handleClose('notification')}
              />
            </div>
          </li> */}

          {/* <li className="nav-item ">
            <span className="nav-link cursor-pointer">
              <i className="la la-th-large cursor-pointer"></i>
            </span>
          </li> */}

          <li className="nav-item ">
            <span
              className="nav-link cursor-pointer"
              onClick={e => handleOpen(e, 'profile')}
            >
              <i className="la la-user"></i>
            </span>
            <div>
              <DropdownMenu
                items={[
                  {
                    title: `${user.first_name} ${user.last_name}`
                  },
                  {
                    title: 'Logout',
                    onClick: () => {
                      authService.logout();
                      history.replace('/login');
                    }
                  }
                ]}
                openElRef={openedMenu.profile}
                onClose={() => handleClose('profile')}
              />
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
};

