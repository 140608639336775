import React, { Component } from 'react';
import ReactFilestack from 'filestack-react';

import MuiDialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import filestackService from 'services/filestack-service';

const Dialog = withStyles({
  container: {},
  paper: {
    backgroundColor: 'transparent',
    color: 'inherit'
  }
})(MuiDialog);

export default class FileUploader extends Component {
  state = { open: false };
  uploadCard = null;

  componentDidMount() {
    this.fillUploadCard();
  }

  fillUploadCard = async () => {
    if (!this.uploadCard)
      this.uploadCard = await filestackService.getUploadCard();
  };

  handelFilePicker = async () => {
    if (!this.uploadCard) await this.fillUploadCard();
    const policy = JSON.parse(atob(this.uploadCard.policy));

    if (Date.now() >= policy.expiry * 1000)
      this.uploadCard = await filestackService.getUploadCard();

    this.setState({ open: true });
  };

  handelUploadSuccess = response => {
    if (!response.filesUploaded || response.filesUploaded.length === 0) return;
    if (this.props.onChange) this.props.onChange(response.filesUploaded[0].url);
    this.setState({ open: false });
  };

  getFilestackOptions = () => {
    let options = '';
    if (this.props.isForceCrop) {
    options = {
      maxSize: 2 * 1024 * 1024,
      maxFiles: 1,
      displayMode: 'inline',
      container: 'filestack-embedded',
       transformations: {
        crop: {
          aspectRatio: 3/2,
          force: true
        }, 
        rotate:true
      }, 
    };
  }
  else {
    options = {
      maxSize: 2 * 1024 * 1024,
      maxFiles: 1,
      displayMode: 'inline',
      container: 'filestack-embedded'
    };
  }

    if (this.state.onlyImages) {
      options.accept = 'image/*';
    }

    return options;
  };

  render() {
    const { name, label, errors, value, uploadBtnLabel } = this.props;

    const { open } = this.state;
    return (
      <div className="form-group">
        {label && <label>{label}</label>}

        <div className="d-inline-block px-2">
          <button
            className="btn btn-primary btn-sm"
            type="button"
            onClick={this.handelFilePicker}
          >
            {uploadBtnLabel}
          </button>
        </div>
        {value && (
          <div className="d-inline-block px-2">
            <img
              className="rounded-circle w-40-px h-40-px "
              src={filestackService.getFileUrl(value)}
              alt={name}
            />
          </div>
        )}

        {open && (
          <Dialog
            open={open}
            fullScreen
            disableBackdropClick
            onClose={() => this.setState({ open: false })}
          >
            <div
              id="filestack-embedded"
              className="h-100 position-relative"
              style={{ margin: 100 }}
            >
              <i
                className="la la-times-circle la-2x position-absolute cursor-pointer"
                style={{ right: 0, zIndex: 10001 }}
                onClick={() => this.setState({ open: false })}
              ></i>
              <ReactFilestack
                apikey={this.uploadCard ? this.uploadCard.apiKey : ''}
                onSuccess={this.handelUploadSuccess}
                actionOptions={this.getFilestackOptions()}
                componentDisplayMode={{ type: 'immediate' }}
                clientOptions={{
                  security: {
                    policy: this.uploadCard ? this.uploadCard.policy : '',
                    signature: this.uploadCard ? this.uploadCard.signature : ''
                  }
                }}
              />
            </div>
          </Dialog>
        )}
        {errors && (
          <small className="form-text text-danger px-1">{errors}</small>
        )}
      </div>
    );
  }
}
