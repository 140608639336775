import React from "react";
import * as yup from "yup";

import authService from "services/auth-service";
import FormBase from "./shared/form-base";
import RecoverDialog from "./recover-dialog";

export default class ResetPassword extends FormBase {
  state = {
    data: {
      password: "",
      confirm_password: ""
    },
    relatedData: {
      password: ["confirm_password"],
      confirm_password: ["password"]
    },
    resetVisiable:false,
    email:"",
    code:"",
    errors: {}
  };

  schema = yup.object().shape({
    password: yup
      .string()
      .nullable()
      .notRequired()
      .label("Password")
      .test("passwords-length", "${label} must be 8 to 15 characters", val => {
        if (!val) return true;
        return val.length >= 8 && val.length <= 15;
      })
      .test("password-required", "${label} is a required field", function(val) {
        if (!this.options.context.componentProps.editItem && !val) return false;
        return this.options.context.confirm_password && !val ? false : true;
      }),

    confirm_password: yup
      .string()
      .test("passwords-match", "Passwords must match", function(val) {
        return val ? this.options.context.password === val : true;
      })
      .test(
        "confirm_password-required",
        "${label} is a required field",
        function(val) {
          if (!this.options.context.componentProps.editItem && !val)
            return false;
          return this.options.context.password && !val ? false : true;
        }
      )
      .label("Confirm password")
  });

  getCode = () => {
    const url = new URL(window.location.href);
    const email = url.searchParams.get("src");
    const code = url.searchParams.get("code");
    this.setState({ email: email });
    this.setState({ code: code });
  };


  handelSubmit = async e => {
    e.preventDefault();
    const result = await authService.resetPassword(this.state.email,this.state.code,this.state.data.password);
    if (result === "success"){
        this.setState({resetVisiable:true})
    }
  };

  componentDidMount (){
    this.getCode();
  }

  render() {
    return (
      <div className="login-wrapper min-h-100-vh">
        <div className="container">
          <div className="row">
            <div className="col">
              <h1 className="text-center my-4 text-primary">ZIPLUNCH</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-8 m-auto">
              <div className="card">
                  <div className="card-body">
                    <h2 className="text-center mb-5 font-weight-normal ">
                      Reset password
                    </h2>
                    <form onSubmit={this.handelSubmit} noValidate>
                      {this.renderInput(
                        "password",
                        "Password",
                        null,
                        "password"
                      )}
                      {this.renderInput(
                        "confirm_password",
                        "Confirm password",
                        null,
                        "password"
                      )}
                      {this.renderSubmitButton(
                        "RESET PASSWORD",
                        " btn-block  btn-lg mb-3 text-white"
                      )}
                    </form>
                    <RecoverDialog
                      title="Success"
                      message="Your password has been reset successfully."
                      show={this.state.resetVisiable}
                      onConfirm={e => {
                        e.stopPropagation();
                        this.props.history.push("/login");
                      }}
                      onCancel={() => {
                        this.setState({
                          resetVisiable: false
                        });
                      }}
                    />
                  </div>              
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
