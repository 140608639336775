import React from 'react';

const Select = ({
  name,
  label = '',
  isValid,
  error = null,
  options = [],
  defaultOption = '---Select item---',
  tagsIds=[],
  ...rest
}) => {
  options = options.filter(tag => tagsIds.indexOf(tag.id) < 0 );
  
  return (
    <div className="form-group">
      {label && <label htmlFor={name}>{label}</label>}
      <select
        id={name}
        name={name}
        className="form-control form-control-lg"
        {...rest}
      >
        {defaultOption && <option value="">{defaultOption}</option>}

        {options.map(op => (
          <option key={op.id || ''} value={op.id}>
            {op.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;
