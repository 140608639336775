import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import MuiMenu from '@material-ui/core/Menu';
import MuiMenuItem from '@material-ui/core/MenuItem';

const MenuItem = withStyles(theme => ({
  root: {
    fontSize: 12,
    lineHeight: 1,
    minHeight: 30
  }
}))(MuiMenuItem);

export default ({ items, openElRef, onClose, minWidth = 150 }) => {
  const Menu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
      minWidth: minWidth
      // overflow: 'visible',
      // '&::before': {
      //   content: '" "',
      //   position: 'absolute',
      //   top: '-20px',
      //   right: '5px',
      //   borderBottom: '10px solid black',
      //   borderRight: '10px solid transparent',
      //   borderLeft: '10px solid transparent',
      //   borderTop: '10px solid transparent',
      //   zIndex: '10'
      // }
    }
  })(props => (
    <MuiMenu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      {...props}
    />
  ));

  const renderMenuItem = (item, key) => {
    if (typeof item === 'string') return <MenuItem key={key}>{item}</MenuItem>;
    return (
      <MenuItem key={key} onClick={item.onClick}>
        {item.title instanceof Function ? item.title() : item.title}
      </MenuItem>
    );
  };

  return (
    <Menu
      anchorEl={openElRef}
      keepMounted
      open={Boolean(openElRef)}
      onClose={onClose}
      onClick={e => {
        e.stopPropagation();
      }}
    >
      {items.map((item, i) => renderMenuItem(item, i))}
    </Menu>
  );
};
