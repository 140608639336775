import httpService from 'services/http-service';
import tokenUtils from 'common/token-utils';
import toastService from 'services/toast-service';
import filestackService from 'services/filestack-service';

const url = 'login';

async function login(email, password, remember) {
  const response = await httpService.post(url, {
    email,
    password
  });
  const token = tokenUtils.decodeToken(response.data.accessToken);

  if (!token || token.realm !== 'admin') {
    if(token.realm !== 'corporate-admin'){
      toastService.error('You cant use this app');
      return false;
    }else {
      window.location.replace('/corporate')
    }
  }

  tokenUtils.setLoggedUser(response.data, remember);
  await filestackService.fillDownloadCard();
  return true;
}

function logout() {
  tokenUtils.removeLoggedUser();
}

function getCurrentUser() {
  return tokenUtils.getLoggedUser();
}

async function recoverPassword(email) {
  const response = await httpService.post("/users/forgot-password", {
    email
    });
    return response.data.result;
}

async function resetPassword(email, code, password) {
  const data = await httpService.post("/users/new-password", {
    email: email,
    code: code,
    password: password
  });
  return data.data.result;
}


export default {
  login,
  logout,
  getCurrentUser,
  recoverPassword,
  resetPassword
};
