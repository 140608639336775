import React from 'react';
import MuiCheckbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';

const Checkbox = withStyles({
  root: {
    padding: 0,
    '&$checked': {
      color: '#fd0e35'
    }
  },
  checked: {}
})(MuiCheckbox);

export default ({ name, label, isValid, errors, value, ...rest }) => {
  return (
    <div className="form-group">
      <Checkbox id={name} name={name} checked={value} {...rest} />
      {label && (
        <label className="form-check-label px-1" htmlFor={name}>
          {label}
        </label>
      )}
      {errors && <small className="form-text text-danger px-1">{errors}</small>}
    </div>
  );
};
