import httpService from './http-service';

const cardKey = 'downloadCard';
const getUploadCard = async () => {
  const response = await httpService.get(`getUploadPolicy`);
  return response.data;
};

const fillDownloadCard = async () => {
  const response = await httpService.get(`getDownloadPolicy`);
  const card = response.data;
  localStorage.setItem(cardKey, JSON.stringify(card));
};

const checkDownloadCardExpiry = () => {
  // this function used to refresh  download card before 7 days of its expiry
  const card = JSON.parse(localStorage.getItem(cardKey));
  if (!card) return fillDownloadCard();

  const policy = JSON.parse(atob(card.policy));

  //add 7 days
  const dateStamp = (new Date().getTime() + 7 * 24 * 60 * 60 * 1000) / 1000;
  if (dateStamp >= policy.expiry) fillDownloadCard();
};

const getFileUrl = url => {
  return `${url}`;
};

export default {
  getUploadCard,
  fillDownloadCard,
  getFileUrl
};
