import React from "react";
import * as yup from "yup";

import authService from "services/auth-service";
import FormBase from "./shared/form-base";
import RecoverDialog from "./recover-dialog";

export default class RecoverPassword extends FormBase {
  state = {
    data: { email: "" },
    finish: false,
    recoverVisiable:false,
    errors: {}
  };

  schema = yup.object().shape({
    email: yup
      .string()
      .email()
      .required()
      .label("Email")
  });

  handelSubmit = async e => {
    e.preventDefault();
    const response = await authService.recoverPassword(this.state.data.email);
    if (response === "success") {
      this.setState({recoverVisiable:true})
    }
  };

  render() {
    return (
      <div className="login-wrapper min-h-100-vh">
        <div className="container">
          <div className="row">
            <div className="col">
              <h1 className="text-center my-4 text-primary">ZIPLUNCH</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-8 m-auto">
              <div className="card">
              {!this.state.finish && (
                <div className="card-body">
                  <h2 className="text-center mb-5 font-weight-normal ">
                    Password recovery
                  </h2>

                  <form onSubmit={this.handelSubmit} noValidate>
                    {this.renderInput(
                      "email",
                      "Your email",
                      "Enter your email"
                    )}
                    {this.renderSubmitButton(
                      "SEND CONFIRMATION",
                      " btn-block  btn-lg mb-3 text-white"
                    )}
                  </form>
                </div>
              )}
                {this.state.finish && (
                  <div className="card-body">
                    <h3>Please check your email</h3>
                    <p >
                      An email with instructions to setup a new password has
                      been sent to {}
                      {this.state.data.email} .
                    </p>
                  </div>
                )}
              </div>
              <RecoverDialog
                      title="Your password has been reset"
                      message="You'll shortly receive an email with instructions to setup a new password."
                      show={this.state.recoverVisiable}
                      onConfirm={e => {
                        e.stopPropagation();
                        this.setState({ finish: true ,recoverVisiable: false});
                      }}
                      onCancel={() => {
                        this.setState({
                          recoverVisiable: false
                        });
                      }}
                    />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
