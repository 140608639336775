import React from 'react';
import ReactTags from 'react-tag-autocomplete';

const classNames = {
  root: 'tags-input',
  rootFocused: 'tags-input-focused',
  selected: 'tags-input-container ',
  selectedTag: 'tags-input-tag',
  selectedTagName: 'tags-input-tag-title',
  search: 'tags-input-search-box',
  searchInput: 'tags-input-search-box-input',
  suggestions: 'tags-input-suggestions',
  suggestionActive: 'is-active',
  suggestionDisabled: 'is-disabled'
};
export default ({
  name,
  label,
  value,
  onChange,
  isValid,
  errors,
  placeholder,
  suggestions,
  allowNew = false
}) => {
  const selectedTags = value ? value : [];
  const selectedTagsIds = selectedTags.map(t => t.id);
  const availableSuggestions = suggestions
    ? suggestions
        .filter(s => !selectedTagsIds.includes(s.id))
        .map(s => ({
          id: s.id,
          name: s.name
        }))
    : [];

  const handleAdd = tag => {
    if (selectedTags.find(i => i.id === tag.id)) return;
    const newTags = [...selectedTags, tag];
    onChange(newTags);
  };

  const handleDelete = index => {
    const newTags = [...selectedTags];
    newTags.splice(index, 1);
    onChange(newTags);
  };

  return (
    <div className="form-group">
      {label && <label htmlFor={name}>{label}</label>}

      <div className="">
        <ReactTags
          tags={selectedTags}
          allowDragDrop={false}
          suggestions={availableSuggestions}
          placeholder={placeholder}
          autocomplete={true}
          allowNew={allowNew}
          classNames={classNames}
          handleDelete={handleDelete}
          handleAddition={handleAdd}
          inputAttributes={{
            id: name,
            name: name
          }}
        />
      </div>

      {errors && <small className="form-text text-danger px-1">{errors}</small>}
    </div>
  );
};
