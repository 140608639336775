import React from 'react';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';

const CustomSwitch = withStyles(theme => ({
  root: {
    width: 40,
    height: 22,
    padding: 0,
    margin: theme.spacing(1)
  },
  switchBase: {
    top: 1.5,
    left: 4,
    padding: 1,
    color: 'white',
    '& + $track': {
      backgroundColor: 'lightgrey',
      border: '1px solid #E8ECEF'
    },
    '&$checked': {
      transform: 'translateX(16px)',
      color: '#00ff00',
      '& + $track': {
        backgroundColor: 'lightgrey',
        opacity: 1,
        border: 'none'
      }
    }
  },
  thumb: {
    width: 16,
    height: 16
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border'])
  },
  checked: {},
  focusVisible: {}
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked
      }}
      {...props}
    />
  );
});

export default ({ name, label, isValid, errors, value, onChange, style, ...rest }) => {
  return (
    <div className="form-group">
      {label && (
        <label className="form-check-label" htmlFor={name}>
          {label}
        </label>
      )}
      <CustomSwitch
        style={style}
        id={name}
        name={name}
        checked={value ? true : false}
        onChange={onChange}
      />

      {errors && <small className="form-text text-danger px-1">{errors}</small>}
    </div>
  );
};
