import jwtDecode from 'jwt-decode';

     const decodeToken = (token) => {
        try {
            return jwtDecode(token);
        } catch (err) {
            console.log(err);
            return null;
        }
    }
    export {decodeToken}