import jwtDecode from 'jwt-decode';

const userKey = 'user';

function setLoggedUser(userData, remember) {
  if (remember) localStorage.setItem(userKey, JSON.stringify(userData));
  else sessionStorage.setItem(userKey, JSON.stringify(userData));
}

function getLoggedUser() {
  let data = sessionStorage.getItem(userKey);
  if (!data) data = localStorage.getItem(userKey);
  if (!data) return null;

  const userData = JSON.parse(data);
  if (!userData) return null;
  const token = decodeToken(userData.accessToken);
  if (isTokensExpired(token)) {
    removeLoggedUser();
    return null;
  }
  return userData;
}

function removeLoggedUser() {
  localStorage.removeItem(userKey);
  sessionStorage.removeItem(userKey);
}

function isTokensExpired(token) {
  return Date.now() >= token.exp * 1000;
}

function refreshLoggedUserToken(newToken) {
  let remember = false;
  let data = sessionStorage.getItem(userKey);
  if (!data) {
    remember = true;
    data = localStorage.getItem(userKey);
  }
  if (!data) return;

  const userData = JSON.parse(data);
  if (!userData) return;
  userData.accessToken = newToken;
  setLoggedUser(userData, remember);
}

function decodeToken(token) {
  try {
    return jwtDecode(token);
  } catch {
    return null;
  }
}

export default {
  setLoggedUser,
  getLoggedUser,
  removeLoggedUser,
  refreshLoggedUserToken,
  decodeToken
};
