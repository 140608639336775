import React, { useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import asyncComponent from 'components/shared/async-component';
import { decodeToken } from 'components/shared/controls/token-decode.js';


const AsyncDashboard = asyncComponent(() => import('components/dashboard/dashboard'));
const AsyncByLocation = asyncComponent(() => import('components/by-location/by-location'));
const AsyncUsers = asyncComponent(() => import('components/users/users'));
const AsyncCustomers = asyncComponent(() => import('components/customers/customers'));
const AsyncCompanies = asyncComponent(() => import('components/companies/companies'));
const AsyncCorporate = asyncComponent(() => import('components/corporate/corporate'));
const AsyncRestaurants = asyncComponent(() => import('components/restaurants/restaurants'));
const AsyncHubs = asyncComponent(() => import('components/hubs/hubs'));
const AsyncTags = asyncComponent(() => import('components/tags/tags'));
const AsyncNeighborhoods = asyncComponent(() => import('components/neighborhoods/neighborhoods'));
const AsyncCategories = asyncComponent(() => import('components/category/category'));
const AsyncOrders = asyncComponent(() => import('components/orders/orders'));
const AsyncNotifications = asyncComponent(() => import('components/notifications/notifications'));
const AsyncExceptions = asyncComponent(() => import('components/exceptions/exceptions'));
const AsyncContent = asyncComponent(() => import('components/content/content'));
// const AsyncSettings = asyncComponent(()=> import ('components/settings/settings'));

export default ({ userRealm }) => {
  return (
    <>
      {userRealm === 'admin' && (
        <Switch>
          <Route path="/dashboard" render={props => <AsyncDashboard {...props} />} />
          <Route path="/by-location" render={props => <AsyncByLocation  {...props} />} />
          <Route path="/admin" render={props => <AsyncUsers {...props} />} />
          <Route path="/customers" render={props => <AsyncCustomers {...props} />} />
          <Route path="/companies" render={props => <AsyncCompanies {...props} />} />
          <Route path="/corporate" render={props => <AsyncCorporate {...props} />} />
          <Route path="/restaurants" render={props => <AsyncRestaurants {...props} />} />
          <Route path="/hubs" render={props => <AsyncHubs {...props} />} />
          <Route path="/tags" render={props => <AsyncTags {...props} />} />
          <Route path="/neighborhoods" render={props => <AsyncNeighborhoods {...props} />} />
          <Route path="/categories" render={props => <AsyncCategories {...props} />} />
          <Route path="/orders" render={props => <AsyncOrders {...props} />} />
          <Route path="/notifications" render={props => <AsyncNotifications {...props} />} />
          {/* <Route path="/settings" render={props => <AsyncSettings {...props} />} /> */}
          <Route path="/content" render={props => <AsyncContent {...props} />} />
          <Route path="/exceptions" render={props => <AsyncExceptions {...props} />} />
          <Redirect from="/" exact to="/dashboard" />
          <Redirect to="/not-found" />
        </Switch>)}
      {userRealm === 'corporate-admin' && (
        <Switch>
          <Route path="/corporate" render={props => <AsyncCorporate {...props} />} />
          <Redirect from="/" exact to="/corporate" />
          <Redirect to="/not-found" />
        </Switch>)}
    </>
  );
};
