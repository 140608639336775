import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

export default ({ location, onToggleSidebar, userRealm }) => {
  const renderMenuHeader = title => {
    return (
      <li className="header-menu">
        <span>{title}</span>
      </li>
    );
  };

  const renderMenuLink = (to, title, icon) => {
    return (
      <li className={location.pathname.startsWith(`/${to}`) ? 'active' : ''}>
        <Link to={to}>
          <i className={`la ${icon}`}></i>
          <span>{title}</span>
        </Link>
      </li>
    );
  };

  return (
    <nav className="sidebar">
      <div className="sidebar-content">
        <div className="sidebar-brand">
          <div className="d-flex">
            <div className="d-lg-none"></div>
            <div>ZIPLUNCH</div>
            <div className="d-lg-none cursor-pointer" onClick={onToggleSidebar}>
              <i className="la la-bars"></i>
            </div>
          </div>
        </div>
        <div className="sidebar-menu">
          <ul>
            {userRealm === 'admin' && (
              <>
                {renderMenuLink('dashboard', 'Dashboard', 'la-dashboard')}

                {renderMenuHeader('Menu Creation')}
                {renderMenuLink('by-location', 'By Delivery Hub', 'la-map-marker')}
                {renderMenuHeader('General Management')}

                {renderMenuLink('customers', 'Customers', 'la-user')}
                {renderMenuLink('restaurants', 'Restaurants', 'la-cutlery')}
                {renderMenuLink('corporate', 'Corporate', 'la-building-o')}
                {renderMenuLink('companies', 'Locations', 'la-flag-o')}
                {renderMenuLink('hubs', 'Delivery Hubs', 'la-map-pin')}

                {renderMenuHeader('Operations')}
                {renderMenuLink('orders', 'Orders', 'la-search')}
                {renderMenuLink('tags', 'Tags', 'la-tags')}
                {renderMenuLink('categories', 'Categories', 'la-tags')}
                {renderMenuLink('neighborhoods', 'Neighborhoods', 'la-flag-o')}
                {renderMenuLink('notifications', 'Notifications', 'la-bell')}
                {renderMenuLink('content', 'Content', 'la-comment')}
                {/* {renderMenuLink('settings', 'Settings', 'la-cog')} */}
                {renderMenuLink('exceptions', 'Exceptions', 'la-exclamation')}
                {renderMenuLink('admin', 'Admin', 'la la-lock')}
              </>)}
            {userRealm === 'corporate-admin' && (
              <>
                {renderMenuLink('corporate', 'Corporate', 'la-building-o')}
              </>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};
